<template>

  <dv-full-screen-container class="bigData">
    <div style="display: flex;flex-direction: column;width: 100%;height: 100%">
      <dv-decoration-5 style="width:100%;height:40px;" />
      <dv-border-box-11 title="MIHOGO smart  platform" style="height: 200px;">

        <div class="header ">
          <div class="f1 fx1">
            total operational income：<span style="color:red">{{ homeData.totalRevenue }}</span>
          </div>
          <div class="fx2">
            total stores：{{ homeData.storeCount }}
          </div>
          <div class="title">
            APP Data Analysis <Center></Center>
          </div>
          <div class="en">
            total users：{{ homeData.userCount }}
          </div>
          <div class="f1 date">
            {{ time }}
          </div>
        </div>
      </dv-border-box-11>
      <dv-decoration-6 style="width:100%;height:30px;" />

      <div class="flexdsfds" style="flex:1;width: 100vw;">

        <div class="left " style="width:520px;">

          <div style="flex:1;">
            <dv-border-box-1>
              <dv-scroll-board :config="config" style="width:90%;height:90%" />
            </dv-border-box-1>
          </div>
          <div style="width:100%;height:310px">
            <dv-border-box-1>
              <dv-scroll-ranking-board :config="paihang" style="width:90%;height:300px" />
            </dv-border-box-1>
          </div>



        </div>
        <dv-decoration-4 style="width:15px;height:100%" />
        <div class="center">
          <dv-border-box-1 class="center_border" style="width: 100%;flex:1;">

            <div id="chart"></div>

          </dv-border-box-1>
          <div style="width: 100%;height: 150px;display: flex;margin-top: 5px;">
            <div class="ccccc">
              <img src="@/assets/zys.png" alt="">
              <div style="margin-right: 50px">
                <span>
                  total revenue
                </span>
                <div>
                  {{ homeData.totalRevenue }}
                </div>
              </div>
            </div>
            <div class="ccccc">
              <img src="@/assets/zys.png" alt="">
              <div style="margin-right: 50px">
                <span>
                  total users
                </span>
                <div>
                  {{ homeData.userCount }}
                </div>
              </div>
            </div>
            <div class="ccccc">
              <img src="@/assets/zys.png" alt="">
              <div style="margin-right: 50px">
                <span>
                  number of devices
                </span>
                <div>
                 
                  {{ homeData.leaseInfo.idleCount+homeData.leaseInfo.leaseCount+homeData.leaseInfo.noRetrunCount }}
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%;height: 150px;display: flex;margin: 5px 0;">
            <div class="ccccc">
              <img src="@/assets/zys.png" alt="">
              <div style="margin-right: 50px">
                <span>
                  total stores
                </span>
                <div>
                  {{ homeData.storeCount }}
                </div>
              </div>
            </div>
            <div class="ccccc">
              <img src="@/assets/xslc.png" alt="">
              <div style="margin-right: 50px">
                <span>
                  riding distance
                </span>
                <div>
                  8986,852,520 KM
                </div>
              </div>
            </div>
            <div class="ccccc">
              <img src="@/assets/zys.png" alt="">
              <div style="margin-right: 50px">
                <span>
                  occupancy rate
                </span>
                <div>
                  {{ Number((homeData.leaseInfo.leaseCount+homeData.leaseInfo.noRetrunCount)/(homeData.leaseInfo.idleCount+homeData.leaseInfo.leaseCount+homeData.leaseInfo.noRetrunCount)*100).toFixed(2) }}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <dv-decoration-4 style="width:15px;height:100%;" />
        <div class="right" style="width: 520px;">



          <dv-border-box-1 style="width: 100%;flex:1;">
            <div id="chart_lease"></div>
          </dv-border-box-1>

          <dv-decoration-5 style="width:100%;height:50px;" />

          <div style="display: flex;justify-content: center;">
            <dv-decoration-12 style="width:150px;height:150px;" />
          </div>




        </div>
      </div>
      <dv-decoration-6 style="width:100%;height:40px;" />




    </div>
  </dv-full-screen-container>

</template>
<script setup>
import { useHome } from "@/use/home"
import { ref, reactive, onMounted, watch } from 'vue';
import * as echarts from 'echarts';
import 'echarts/extension/bmap/bmap';
const { homeData } = useHome();
const config = ref({})
const setOption3 = (data) => {

  setTimeout(() => {
    myChart2.value.setOption({
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: data.date
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {

          data: data.value,
          type: 'line',
          areaStyle: {},
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
        }
      ]
    })
  }, 3000);
}

watch(() => homeData.day7, (data) => {
  setOption3(data)
})
watch(() => homeData.newOrderList, (val) => {
  console.log(val)
  config.value = {
    header: ['门店', '用户手机号', '金额'],
    rowNum: 10,
    data: val.map(i => { return [i.name, i.phone, i.price] })
  }
  ////config.value.data.push(['佛山店22', '15863687545', '299'])
  //console.log(config);
})

watch(() => homeData.storeOrderTop10, (val) => {
  paihang.value = {
    data: val.map(i => { return { name: i.name, value: i.value } }),
    colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
    unit: '元'
  }
})
const paihang = ref({})
const myChart = ref({})
const myChart2 = ref({})
const data = ref([])
watch(() => homeData.storeRank, (val) => {

  let names = new Set()

  let newData = []
  val.forEach(i => {

    let name = (i.pre_name + "").substr(0, 2)
    geoCoordMap[name] = [i.lng, i.lat]

    if (names.has(name)) {

      newData.forEach(j => {
        if (j.name == name) {
          j.value++
        }
      })
    } else {
      names.add(name)
      newData.push({
        name,
        value: 1
      })
    }



  })

  data.value = newData


  option.series[0].data = convertData(data.value)
  option.series[1].data = convertData(data.value)
})

const geoCoordMap = reactive({})
const time = ref("正在读取")
const getTime = () => {
  let t = new Date()
  time.value = `${t.getFullYear()}-${t.getMonth() + 1} - ${t.getDate()}:${t.getHours()}:${t.getMinutes()}:${t.getSeconds()}`

}
const convertData = (data) => {
  var res = [];
  for (var i = 0; i < data.length; i++) {
    var geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value)
      });
    }
  }
  return res;
}
const option = reactive({
  title: {
    text: 'MIHOGO smart  platform',
    subtext: 'Store Distribution and Ranking',

    left: 'center'
  },
  tooltip: {
    trigger: 'item'
  },
  bmap: {
    center: [113.31, 22.52],
    zoom: 9.5,
    roam: true,
    mapStyle: {
      styleJson: [
        {
          featureType: 'water',
          elementType: 'all',
          stylers: {
            color: '#d1d1d1'
          }
        },
        {
          featureType: 'land',
          elementType: 'all',
          stylers: {
            color: '#f3f3f3'
          }
        },
        {
          featureType: 'railway',
          elementType: 'all',
          stylers: {
            visibility: 'off'
          }
        },
        {
          featureType: 'highway',
          elementType: 'all',
          stylers: {
            color: '#fdfdfd',
            visibility: 'off'
          }
        },
        {
          featureType: 'highway',
          elementType: 'labels',
          stylers: {
            visibility: 'off'
          }
        },
        {
          featureType: 'arterial',
          elementType: 'geometry',
          stylers: {
            color: '#fefefe',
            visibility: 'off'
          }
        },
        {
          featureType: 'arterial',
          elementType: 'geometry.fill',
          stylers: {
            color: '#fefefe',
            visibility: 'off'
          }
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: {
            visibility: 'off'
          }
        },
        {
          featureType: 'green',
          elementType: 'all',
          stylers: {
            visibility: 'off'
          }
        },
        {
          featureType: 'subway',
          elementType: 'all',
          stylers: {
            visibility: 'off'
          }
        },
        {
          featureType: 'manmade',
          elementType: 'all',
          stylers: {
            color: '#d1d1d1',
            visibility: 'off'
          }
        },
        {
          featureType: 'local',
          elementType: 'all',
          stylers: {
            color: '#d1d1d1',
            visibility: 'off'
          }
        },
        {
          featureType: 'arterial',
          elementType: 'labels',
          stylers: {
            visibility: 'off'
          }
        },
        {
          featureType: 'boundary',
          elementType: 'all',
          stylers: {
            color: '#fe00fe'
          }
        },
        {
          featureType: 'building',
          elementType: 'all',
          stylers: {
            color: '#d100d1'
          }
        },
        {
          featureType: 'label',
          elementType: 'labels.text.fill',
          stylers: {
            color: '#aaaaaa'
          }
        }
      ]
    }
  },
  series: [
    {
      name: 'pm2.5',
      type: 'scatter',
      coordinateSystem: 'bmap',
      data: convertData(data.value),
      symbolSize: function (val) {
        return val[2] / 10;
      },
      encode: {
        value: 2
      },
      label: {
        formatter: '{b}',
        position: 'right',
        show: false
      },
      emphasis: {
        label: {
          show: true
        }
      }
    },
    {
      name: '门店数',
      type: 'effectScatter',
      coordinateSystem: 'bmap',
      data: convertData(
        data.value
          .sort(function (a, b) {
            return b.value - a.value;
          })
          .slice(0, 6)
      ),
      symbolSize: function (val) {
        return val[2] / 10;
      },
      encode: {
        value: 2
      },
      showEffectOn: 'render',
      rippleEffect: {
        brushType: 'stroke'
      },
      label: {
        formatter: '{b}',
        position: 'right',
        show: true
      },
      itemStyle: {
        shadowBlur: 10,
        shadowColor: '#990099'
      },
      emphasis: {
        scale: true
      },
      zlevel: 1
    }
  ]
})
const lease_option = {
  legend: {
    top: 'bottom'
  },
  toolbox: {
    show: true,
    feature: {
      mark: { show: true },
      dataView: { show: true, readOnly: false },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  series: [
    {
      name: 'Nightingale Chart',
      type: 'pie',
      radius: [50, 250],
      center: ['50%', '50%'],
      roseType: 'area',
      itemStyle: {
        borderRadius: 8
      },
      data: [

        { value: 22, name: 'rose 7' },
        { value: 18, name: 'rose 8' }
      ]
    }
  ]
};

setInterval(() => {
  getTime()

}, 1000)
onMounted(() => {
  setTimeout(() => {
    myChart.value = echarts.init(document.getElementById("chart"))
    myChart2.value = echarts.init(document.getElementById("chart_lease"))
    //myChart2.value.setOption(lease_option)
    myChart.value.setOption(option)

    //var map = L.map('chart').setView([51.505, -0.09], 13);
  }, 1000);


})
</script>
<style scoped lang="scss">
.flexdsfds {
  display: flex;
  width: 100%;

  .left,
  .right {

    text-align: center;
    display: flex;
    flex-direction: column;
  }
}

.ccccc {
  margin: 0 5px;
  border: 2px solid #666;
  flex: 1;
  background-color: #0F1629;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  align-items: center;
  span{
    font-size: 18px;
  
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.center {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  #chart {

    height: 100%;
    width: 100%;

  }
}



.bigData {


  background-color: #000;
  height: 100vh;
  color: #fff;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;

  --base-color: #111554;
}


#chart_lease {
  height: 100%;
  width: 100%;

  border-radius: 15rpx;



}

.header {
  display: flex;
  align-items: center;
  width: 100%;


  padding: 50px;
  box-sizing: border-box;

  div {
    margin-top: 20px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }

  .title {
    color: #fff;
    font-size: 35px;
  }

  .f1 {
    flex: 1;
    text-align: center;
  }

  .fx1 {
    background-color: #282C34;
    background: linear-gradient(-135deg, transparent 50px, var(--base-color) 0) right bottom;
  }

  .fx2 {

    width: 15%;
    text-align: center;

    background:

      linear-gradient(45deg, transparent 44.5px, var(--base-color) 0)bottom left,
      linear-gradient(-135deg, transparent 24px, var(--base-color) 0)bottom right,
      linear-gradient(-135deg, transparent 44.5px, var(--base-color) 0)top right,
      linear-gradient(50deg, transparent 26.5px, var(--base-color) 0)top left;
    background-size: 51% 51%;
    background-repeat: no-repeat;
  }

  .en {
    width: 15%;
    text-align: center;
    background-color: #282C34;
    background:
      linear-gradient(135deg, transparent 24px, var(--base-color) 0)bottom left,
      linear-gradient(-45deg, transparent 44.5px, var(--base-color) 0)bottom right,
      linear-gradient(-45deg, transparent 24px, var(--base-color) 0)top right,
      linear-gradient(135deg, transparent 44.5px, var(--base-color) 0)top left;
    background-size: 51% 51%;
    background-repeat: no-repeat;
  }

  .date {
    text-align: center;
    background-color: #282C34;
    background: linear-gradient(135deg, transparent 50px, var(--base-color) 0) right bottom;
  }
}
</style>