<template>
    <div>

        <FormComponent :data="data" title="添加原材料" @submit="submit" canEdit="true">

        </FormComponent>
    </div>
</template>

<script setup>
import FormComponent from '@/components/FormComponent.vue';
import { addItem } from "@/api/purchase.js"
import { reactive } from 'vue';
import { ElMessage } from 'element-plus';
const data = reactive({
    name: {
        require: true,
        label: "名称",
        value: "",
        type: "text"
    },
    remake: {
        label: "备注",
        value: "",
        type: "text"
    }
})
const submit = async (data) => {

    const { code, msg } = await addItem(data);
    if (code == 200) {
        ElMessage.success({ type: 'success', message: msg })
    } else {
        ElMessage.error(msg);
    }

}
</script>

<style lang="scss" scoped>
</style>