<template>
    <div>
        <FormComponent title="修改门店资金" :data="formData" canEdit="true" @submit="xgbalance"></FormComponent>
    </div>
</template>
<script setup>
import {ref} from "vue"
import FormComponent from '@/components/FormComponent.vue';
import {useXgBalance} from "@/use/finance"
let file=null
const {formData,xgbalance}=useXgBalance();

</script>
