import axios from "axios"

export async function getRoleList(){
    const token=localStorage.getItem("token");
    let {data}=await axios.post("/role/index",{token});

    return data;

}

export async function getRoleForAdmin(role_id){
    const token=localStorage.getItem("token");
    let {data}=await axios.post("/role/getRoleForAdmin",{token,role_id});

    return data;

}
export async function setRoleForAdmin(role_id,menu_list){
    const token=localStorage.getItem("token");
    let {data}=await axios.post("/role/setRoleForAdmin",{token,role_id,menu_list});

    return data;

}
