<template>

    <div>

        <div class="title">
            权限分配
        </div>
       <div class="flex">

        <div class="left">
            

            <div class="title">
                权限列表
            </div>
             <el-tree 
        ref="treeRef"
        :data="menuList"   
        show-checkbox
        node-key="id" 
        :props="defaultProps" 
        :expand-on-click-node="false" 
        
        
   
         highlight-current
        
        >
        
        </el-tree>
        </div>
        
        <div class="right">
            <div class="title">角色列表</div>
            <div class="role_list">
                <div v-for="role in roleList" :key="role.id" @click="currRole=role.id" :class="currRole==role.id?'select':'a'">
                    {{role.name}}
                </div>
            </div>
            <el-button class="save" @click="save">保存</el-button>
       </div>
       </div>
    </div>
</template>

<script setup>
import { getMenuList } from "@/api/menu.js"
import { getRoleList,getRoleForAdmin,setRoleForAdmin } from "@/api/role.js"
import { ref, onMounted, watchEffect } from "vue"
import {ElMessage} from "element-plus"
const customNodeClass = (data, node) => {
  if (data.order) {
    return 'is-penultimate'
  }
  return null
}
const defaultProps = {
    children: 'children',
    label: 'label',
    class: customNodeClass
}
let menuList = ref([]);
let roleList=ref([]);
const currRole=ref(0);
const currUserRoleList=ref([]);
const treeRef = ref();
onMounted(async () => {
    let { data } = await getMenuList(true);
    
    menuList.value = data.map(i => { let children = []; if (i.menuItems) { children = i.menuItems.map(j => { return { label: j.name, id: j.id,show:j.show,order:j.order } }) } return { label: i.name, children, id: i.id,show:i.show,order:i.order } });
    data=await getRoleList();
    roleList.value=data.data;
    currRole.value=data.data[0].id;
    data=await getRoleForAdmin(currRole.value);
    currUserRoleList.value=data.data;
    treeRef.value.setCheckedKeys(currUserRoleList.value, false)

})
watchEffect(async ()=>{
    let data=await getRoleForAdmin(currRole.value);
    currUserRoleList.value=data.data;
    treeRef.value.setCheckedKeys(currUserRoleList.value, false)
})
const save=async ()=>{
    let m=treeRef.value.getCheckedKeys(false);
    
    const {code,msg}=await setRoleForAdmin(currRole.value,m);
    if(code==200){
      ElMessage({type: 'success',message:msg});
    }else{
     ElMessage.error(msg);
    }
}
</script>
<style  lang="scss">

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  a{
    color: blueviolet;
  }
  input{
    width: 50px;
    border:1px solid blueviolet;
    border-radius: 35px;
    text-align: center;

  }
}
.el-tree{
    padding: 20px;
    border-radius: 15px;
    box-shadow: 15px 15px 15px #eee;
.is-penultimate{
    margin: 20px;
}
}
.flex{
    display: flex;
    width: 100%;
    .left{
            width: 70%;
            text-align: center;
        }
    .right{
        flex:1;
        margin-left: 20px;
        text-align: center;
            .save{
                margin-top: 15px;
                width: 80%;
                background-color: blueviolet;
                color: #fff;
                height: 50px;
                line-height: 50px;
            }
    }
    .role_list{
        background-color: #fff;
        
        
        padding: 20px;
        border-radius: 15px;
        box-shadow: 15px 15px 15px #eee;
        min-height: 400px;
         div{
            height: 50px;
            line-height: 50px;
            cursor:pointer;
            &:hover{
                background-color: #f7f7f7;
            }
        }
        .select{
             background-color: blueviolet;
             border-radius: 15px;
             color: #fff;
             &:hover{
                background-color: blueviolet;
            }
        }
    }
}
</style>