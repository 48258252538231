import { ref } from "vue";
import {ruleindex} from "@/api/rule"
export function useRule(){
    const ruleList=ref([]);
    const ruleList_option=ref([])
    ruleindex().then(data=>{
        ruleList.value=data.data
        ruleList_option.value=data.data.map(i=>{
            return {value:i.id,label:i.name}
        })
    })
    return {ruleList,ruleList_option}
}