<template>

<div>
    <SearchBarComponent></SearchBarComponent>

    <div class="title">供应商列表</div>
    <div class="add">
            <router-link to="/purchase/addsupplier">
                <el-button class="btn" type="primary" @click="add">新增</el-button>
            </router-link>

        </div>
    <TableComponent :headers="headers" :data="formData"></TableComponent>
</div>

</template>
<script setup>
import { getSupplierList } from "@/api/purchase"
import SearchBarComponent from '@/components/SearchBarComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import { ElMessage } from "element-plus";
import { onMounted, reactive, ref } from "vue";
const headers = [
    {
        prop: "id",
        label: "ID"
    },
    {
        prop: "name",
        label: "名称"
    },
    {
        prop: "remake",
        label: "备注"
    },
    {
        prop: "caozuo",
        label: "操作"
    }
]
const formData = ref([]);
onMounted(async () => {
    const { code, data, msg } = await getSupplierList();
    if (code == 200) {
        formData.value = data;
    } else {
        ElMessage.error(msg)
    }
})
</script>