<template>
    <div>

        <FormComponent :isUpdateForm="isUpdateForm" :data="formData" :title="title" @submit="submit" @itemclick="itemclick" :canEdit="canEdit">

        </FormComponent>
        <template v-if="id">
        <div class="title">
            订单总额:{{total_price}} 已支付：{{pay_price}}  欠款:{{total_price-pay_price}}
        </div>
        
        </template>

    </div>
</template>

<script setup>
import FormComponent from '@/components/FormComponent.vue';
import { addPurchase } from "@/api/purchase.js"
import { reactive, onMounted, ref,computed } from 'vue';
import { ElMessage } from 'element-plus';
import { getSupplierList, getItemList, getPurchase ,updatePurchase} from "@/api/purchase"
import { useRoute } from 'vue-router';
const supplierList = ref([]);
const itemList = ref([]);
const canEdit=ref(true);
const isUpdateForm=ref(false);
const title=ref("订单录入");
const id=ref();
const total_price=ref(0);
const pay_price=ref(0);
const itemclick = (item, btn) => {
    if (btn.name == "add") {
        let nc = item.children[0];
        item.children.push(JSON.parse(JSON.stringify(nc)));
    } else {
        if (item.children.length > 1)
            item.children.pop()
    }

}
const formData = reactive({
    order_num: {
        require: true,
        label: "订单号",
        value: "",
        type: "text",
        placeholder: "输入订单号"
    },
    supplier_id: {
        require: true,
        label: "供应商",
        value: "",
        type: "select",
        options: supplierList,
        placeholder: "选择供应商"
    },
    stores: {
        label: "原材料",
        name: "stores",
        require: true,
        children: [
            {
                type: "array",
                require: true,
                label: "原材料",
                children: [
                    {
                        name: "erp_item_id",
                        require: true,
                        label: "原材料",
                        value: "",
                        type: "select",
                        options: itemList,
                        placeholder: "选择原材料"
                    },
                    {
                        name: "price",
                        require: true,
                        label: "价格",
                        value: "",
                        type: "text",
                        placeholder: "输入价格"
                    },
                    {
                        name: "num",
                        require: true,
                        label: "数量",
                        value: "",
                        type: "text",
                        placeholder: "输入数量"
                    },
                    {
                        name: "add",
                        value: "添加",
                        type: "button",

                    },
                    {
                        name: "remove",
                        value: "移除",
                        type: "button",

                    }
                ]
            }]
    },
    pay_plan: {
        label: "付款批次",
        name: "pay_plans",
        children: [
            {
                type: "array",
                require: true,
                label: "付款计划",
                children: [
                    {
                        name: "name",
                        require: true,
                        label: "计划名称",
                        value: "",
                        type: "text",
                        placeholder: "输入计划名称"
                    },
                    {
                        name: "plan_pay_time",
                        require: true,
                        label: "计划支付时间",
                        value: "",
                        type: "date",

                        placeholder: "选择计划支付时间"
                    },
                    {
                        name: "pay_time",

                        label: "实际支付时间",
                        value: "",
                        type: "date",
                        placeholder: "选择实际支付时间"
                    },
                    {
                        name: "price",
                        require: true,
                        label: "付款金额",
                        value: "",
                        type: "text",
                        placeholder: "输入付款价格"
                    },
                    {
                        name: "remark",
                        
                        label: "备注",
                        value: "",
                        type: "text",
                        placeholder: "输入备注"
                    },
                    {
                        name: "add",
                        value: "添加",
                        type: "button",

                    },
                    {
                        name: "remove",
                        value: "移除",
                        type: "button",

                    }
                ]
            }]
    },
    ship_date: {
        require: true,
        label: "发货日期",
        value: "",
        type: "date",
        placeholder: "选择发货日期"
    },
    is_daohuo: {

        label: "是否到货",
        value: false,
        type: "switch",
        placeholder: ""
    },
    is_pay: {
        label: "是否已付款",
        value: false,
        type: "switch",
        placeholder: ""
    },
    // daohuo_dan: {

    //     label: "到货单",
    //     value: "",
    //     name:"daohuo_dan",
    //     type: "file",
    //     placeholder: ""
    // },

    // fukuan_dan: {
    //     name:"fukuan_dan",
    //     label: "付款单",
    //     value: "",
    //     type: "file",
    //     placeholder: ""
    // },
    remark: {
        label: "备注",
        value: "",
        type: "text"
        ,
        placeholder: "输入备注"
    }
})
const route = useRoute();

onMounted(async () => {

    const { data } = await getSupplierList();
    supplierList.value = data.map(i => {
        return { label: i.name, value: i.id }
    })

    const res = await getItemList();
    itemList.value = res.data.map(i => {
        return { label: i.name, value: i.id }
    })
    const { order_id, isUpdate } = route.query;
    
    if(order_id&&isUpdate==0){
        canEdit.value=false;
        title.value="采购单详情"
        
    }
    isUpdateForm.value=(isUpdate=="1");
    if (order_id) {
        id.value=order_id;
        const res = await getPurchase(order_id);
        let order = res.data;
        total_price.value=order.total_price;
        pay_price.value=order.pay_price;
        for (let key in formData) {
            let c1 = formData[key].children;
            if (c1) {
                for (let i in c1) {
                    let c2 = c1[i].children;

                    if (c2) {
                        for (let j in c2) {
                            if(c2[j].type!="button")
                            c2[j].value = order[key][i][c2[j].name];
                        }
                    }
                }
            } else {
                formData[key].value = order[key];
            }

        }

    }

})
const submit = async (params) => {
    let data;
    if(isUpdateForm.value&&id.value){
        params.append("id",id.value)
        data = await updatePurchase(params);
    }else{
        data = await addPurchase(params);
    }
    const {code,msg}=data;
    if (code == 200) {
        ElMessage.success({ type: 'success', message: msg })
    } else {
        ElMessage.error(msg);
    }

}
</script>

<style lang="scss" scoped>
</style>