<template>
  <div class="addmenu">

    <div class="title">
        添加菜单
    </div>

   

 
    <el-form :model="form" label-width="120px">
    <el-form-item label="上级菜单">
      <el-select v-model="form.parent_id" clearable placeholder="父菜单-不选则是一级菜单" >
    <el-option
      v-for="item in menuList"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
    </el-form-item>
    <el-form-item label="菜单名">
      <el-input v-model="form.name" />
    </el-form-item>
    <el-form-item label="URL">
      <el-input v-model="form.url" />
    </el-form-item>
    <el-form-item label="权重">
      <el-input v-model="form.order_num" />
    </el-form-item>

    
   <el-form-item label="是否显示">
      <el-switch v-model="form.show" />
    </el-form-item>
    
    
    <el-form-item label="增删查改">
      <el-switch v-model="form.isAddCURD" />
    </el-form-item>
    <el-form-item label="表名" v-if="form.isAddCURD">
      <el-input v-model="form.tableName" />
    </el-form-item>
  
    <el-form-item>
      <el-button type="primary" @click="onSubmit">创建</el-button>
      <el-button>清除</el-button>
    </el-form-item>
  </el-form>
    
  </div>
</template>
<script>

</script>
<script  setup>

import {getMenuList,addMenu} from "@/api/menu.js"
import {ElMessage} from "element-plus"
import { reactive,onMounted } from 'vue'
import { ref } from 'vue'
let menuList =ref([]);


onMounted(async ()=>{
  const data=await getMenuList();
  
  let list=data.data.map(i=>({value:i.id,label:i.name}));
  menuList.value.push(...list);

})


const form = reactive({
  name: '',
  url:'',
  show:true,
  parent_id:"",
  order_num:0,
  isAddCURD:false,
  tableName:""
})
const onSubmit = async () => {
    
    if(form.name==""){
        ElMessage.error("菜单名不允许空");
        return;
    }
    if(form.url==""){
        ElMessage.error("URL不允许空");
        return;
    }
    console.log(form);
  let {msg,code}= await addMenu(form);
  if(code==200){
      ElMessage({type: 'success',message:msg});
    }else{
     ElMessage.error(msg);
    }
}
</script>

<style scoped lang="scss">
.el-form{
    width: 500px;
}

</style>
