let autoRoutes=[];
export function a(){
    return autoRoutes;
};

autoRoutes.push({
    path:'/userMan',
    children:[
        {
            path:'index',
            component: () => import(/* webpackChunkName: 'userManIndex' */ '../pages/userMan/userManIndex.vue')
        },
        {
            path:'add',
            component: () => import(/* webpackChunkName: 'userManAdd' */ '../pages/userMan/userManAdd.vue')
        },
        {
            path:'update',
            component: () => import(/* webpackChunkName: 'userManUpdate' */ '../pages/userMan/userManUpdate.vue')
        }
    ]
})

autoRoutes.push({
    path:'/ld',
    children:[
        {
            path:'index',
            component: () => import(/* webpackChunkName: 'ldIndex' */ '../pages/ld/ldIndex.vue')
        },
        {
            path:'add',
            component: () => import(/* webpackChunkName: 'ldAdd' */ '../pages/ld/ldAdd.vue')
        },
        {
            path:'update',
            component: () => import(/* webpackChunkName: 'ldUpdate' */ '../pages/ld/ldUpdate.vue')
        }
    ]
})


