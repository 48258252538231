<template>

    <div>
       
        <FormComponent @submit="submit" title="新增计费规则" :data="data" canEdit="true"></FormComponent>
    </div>
    </template>
    
    <script setup>
    import FormComponent from '@/components/FormComponent.vue';
    import {useAddBillingRule} from "@/use/billingRule"
    
    const {data,submit}=useAddBillingRule();
    </script>