import axios from "axios"
let config = {
    headers: {'Content-Type': 'multipart/form-data'}
 }
export async function addItem(params){
    const token=localStorage.getItem("token");
    params.append("token",token);
    let {data}=await axios.post("/purchase/additem",params,config);

    return data;

}

export async function addSupplier(params){
    const token=localStorage.getItem("token");
    params.append("token",token);
    let {data}=await axios.post("/purchase/addSupplier",params,config);

    return data;

}
export async function addPurchase(params){
    const token=localStorage.getItem("token");
    
     params.append("token",token);
    let {data}=await axios.post("/purchase/addPurchase",params,config);

    return data;

}
export async function updatePurchase(params){
    const token=localStorage.getItem("token");
    
     params.append("token",token);
    let {data}=await axios.post("/purchase/updatePurchase",params,config);

    return data;

}


export async function getPurchaseList(params={}){
    const token=localStorage.getItem("token");
    let {data}=await axios.post("/purchase/getPurchaseList",{token,...params});

    return data;

}
export async function getPurchase(order_id){
    const token=localStorage.getItem("token");
    let {data}=await axios.post("/purchase/getPurchase",{token,order_id});

    return data;

}
export async function getSupplierList(){
    const token=localStorage.getItem("token");
    let {data}=await axios.post("/purchase/getSupplierList",{token});

    return data;

}


export async function getItemList(){
    const token=localStorage.getItem("token");
    let {data}=await axios.post("/purchase/getItemList",{token});

    return data;

}


