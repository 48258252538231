import { getMenuList } from "@/api/menu.js"
import { ElMessage } from "element-plus";
import { ref } from "vue";
export  function useGetMenuList(){
    const menus=ref([]);
    getMenuList().then((data)=>{
        if(data.code==200){
            menus.value=data.data;
        }else{
            ElMessage.error(data.msg);
        }
    })
    return {menus}
}