<template>

    <RouterLink to="/test/testindex/tap1">标签1</RouterLink>

    <RouterLink to="/test/testindex/tap2"> 标签2</RouterLink>


    <router-view v-slot="{ Component }">
        <keep-alive>
            <component :is=Component />
        </keep-alive>
    </router-view>
</template>