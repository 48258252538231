<template>

    <div>
        <SearchBarComponent :searchData="searchData" @onSearch="onSearch"></SearchBarComponent>

        <div class="title">采购列表</div>
        <div class="add">
            <router-link to="/purchase/addpurchase">
                <el-button class="btn" type="primary" @click="add">录入</el-button>
            </router-link>

        </div>
        <TableComponent :headers="headers" :data="formData" :showSummary="true">
            <template #caozuo="item">
                <el-button type="primary" @click="click(item.row, 0)">
                    查看
                </el-button>
                <el-button type="warning" @click="click(item.row, 1)">
                    修改
                </el-button>
                <el-button type="warning" @click="click(item.row, 2)">
                    删除
                </el-button>
            </template>
            <template #ship_date="item">
            
             <el-date-picker  disabled="true" v-model="item.row.ship_date" type="date" />
            </template>
        </TableComponent>
    </div>

</template>
<script setup>
import { getPurchaseList,getSupplierList } from "@/api/purchase"
import SearchBarComponent from '@/components/SearchBarComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import { ElMessage } from "element-plus";
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
const purchaseList=ref([]);
const searchData =reactive({
    supplier_id: {
        value: "",
        type: "select",
        label: "供应商",
        placeholder:"选择供应商",
        options:purchaseList.value
    },
    order_num: {
        value: "",
        type: "text",
        label: "订单号",
        placeholder:"输入订单号",
    },
    state: {
        value: "",
        type: "select",
        label: "订单状态",
        placeholder:"选择订单状态",
        options: [
        {
            value: 1,
            label: "已付款未发货",
        },
        {
            value: 2,
            label: "已到货未付款",
        },
        {
            value: 3,
            label: "已完成"
        }
        ]

    }
})
const headers = [
    {
        prop: "id",
        label: "ID"
    },
    {
        prop: "name",
        label: "供应商"
    },
    {
        prop: "total_price",
        label: "订单总额"
    },
    {
        prop:"arrears",
        label:"欠款"
    },
    {
        prop: "order_num",
        label: "订单号"
    },
    {
        prop:"ship_date",
        label:"发货时间"
    },
    {
        prop: "state",
        label: "状态"
    },
    {
        prop: "remark",
        label: "备注"
    },
    {
        prop: "caozuo",
        label: "操作"
    }
]
const formData = ref([]);
const router = useRouter();
const click = (item, isUpdate) => {
    if(isUpdate==2){
        
        return;
    }
    router.push({ path: "/purchase/addpurchase", query: { order_id: item.id, isUpdate } })
}
onMounted(async () => {
    const { code, data, msg } = await getPurchaseList();
    const res=await getSupplierList();
    searchData.supplier_id.options=res.data.map(i=>{
        return {value:i.id,label:i.name}
    })
    

    if (code == 200) {
        for (let i of data) {
            i.state = "未到货，未付款";
            if (i.is_daohuo) {
                i.state = "已到货";
                if (i.is_pay) {
                    i.state = "已完成"
                }
            }
            if (i.is_pay) {
                i.state = "已付款";
                if (i.is_daohuo) {
                    i.state = "已完成"
                }
            }
        }
        formData.value = data;

    } else {
        ElMessage.error(msg)
    }
})
const onSearch=async (values)=>{
    const {data,code}=await getPurchaseList(values);
    if (code == 200) {
        for (let i of data) {
            i.state = "未到货，未付款";
            if (i.is_daohuo) {
                i.state = "已到货";
                if (i.is_pay) {
                    i.state = "已到货，并且已付款"
                }
            }
            if (i.is_pay) {
                i.state = "已付款";
                if (i.is_daohuo) {
                    i.state = "已到货，并且已付款"
                }
            }
        }
        formData.value = data;
    }

}
</script>