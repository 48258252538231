<template>
    <div class="homepage">

        <div class="title" style="margin: 0;text-align: center;">
            雷克智控租车后台管理系统
        </div>



        <div class="cardlist">
            <div class="card" v-if="store.user_type == 1">
                <div>
                    <svg t="1680055021147" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="12576" width="60" height="60">
                        <path
                            d="M835.5 743.9c-1-2.5-2.1-4.9-3.2-7.4-13.3-29.5-50.6-38.9-76.3-19.2l-0.6 0.6c-17 17-21.7 42.6-12.3 64.7 13.1 30.7 20.3 64.4 20.3 99.8 0 1.5-1.2 2.7-2.7 2.7H254.6c-1.5 0-2.7-1.2-2.7-2.7 0-138.8 111.3-250.8 249.3-254.4h4c13.1 0 26-1 38.7-3 4.9-0.7 9.9-1.6 15-2.8 0.3-0.1 0.6-0.1 1-0.2 40.4-9 77.9-28 109.4-55.8 0.2-0.2 0.5-0.4 0.7-0.7 3.8-3.4 7.5-6.9 11.2-10.5 47-47 72.9-109.6 72.9-176.1s-25.9-129-72.9-176.1c-47-47-109.6-72.9-176.1-72.9s-129 25.9-176.1 72.9c-47 47-72.9 109.6-72.9 176.1S282 507.9 329 555c4.2 4.2 8.6 8.3 13.1 12.2-31.7 16.6-60.5 37.9-86.1 63.4-32.7 32.7-58.3 70.7-76.2 113.1-18.6 43.9-28 90.5-28 138.5 0 20.5 1.8 41.1 5.3 61.3 4.2 24 25 41.4 49.3 41.4h602.5c24.3 0 45.1-17.5 49.3-41.4 3.5-20.2 5.3-40.8 5.3-61.3 0-47.8-9.4-94.4-28-138.3z m-326-513.7c78.8 2.3 142.7 66.4 144.6 145.2C656 453 598.2 517.7 523.5 527c-5.3-0.2-10.5-0.4-15.8-0.4-6.5 0-13 0.2-19.4 0.5-74.2-8.4-132.1-71.6-132.1-148-0.1-83.6 69.1-151.4 153.3-148.9z"
                            fill="#80DEEA" opacity=".8" p-id="12577"></path>
                        <path d="M212.9 929.3m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3"
                            opacity=".8" p-id="12578"></path>
                        <path d="M416.9 573.9m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3"
                            opacity=".8" p-id="12579"></path>
                        <path d="M802.5 929.8m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3"
                            opacity=".8" p-id="12580"></path>
                    </svg>
                </div>
                <div>

                    <div class="num">
                        {{ homeData.userCount }}

                    </div>
                    <div class="cardtitle">
                        用户数量
                    </div>
                </div>
            </div>
            <div class="card">
                <div>
                    <svg t="1680054682987" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="6987" width="60" height="60">
                        <path d="M390.095238 755.809524h243.809524v146.285714H390.095238z" fill="#f4ea2a" p-id="6988">
                        </path>
                        <path
                            d="M731.428571 975.238095H292.571429c-26.819048 0-48.761905-21.942857-48.761905-48.761905s21.942857-48.761905 48.761905-48.761904h438.857142c26.819048 0 48.761905 21.942857 48.761905 48.761904s-21.942857 48.761905-48.761905 48.761905zM926.47619 780.190476H97.52381c-53.638095 0-97.52381-43.885714-97.52381-97.523809V146.285714c0-53.638095 43.885714-97.52381 97.52381-97.523809h828.95238c53.638095 0 97.52381 43.885714 97.52381 97.523809v536.380953c0 53.638095-43.885714 97.52381-97.52381 97.523809z"
                            fill="#f4ea2a" p-id="6989" data-spm-anchor-id="a313x.7781069.0.i20" class=""></path>
                        <path
                            d="M195.047619 572.952381c-14.628571-14.628571-14.628571-36.571429 0-51.2l185.295238-185.295238c14.628571-14.628571 36.571429-14.628571 51.2 0l131.657143 131.657143 212.114286-212.114286c14.628571-14.628571 36.571429-14.628571 51.2 0 14.628571 14.628571 14.628571 36.571429 0 51.2l-238.933334 238.933333c-14.628571 14.628571-36.571429 14.628571-51.2 0L407.161905 414.47619l-158.476191 158.476191c-17.066667 14.628571-39.009524 14.628571-53.638095 0z"
                            fill="#13227a" p-id="6990" data-spm-anchor-id="a313x.7781069.0.i21" class="selected"></path>
                    </svg>
                </div>
                <div>

                    <div class="num" style="color:#f00">
                        {{ homeData.totalRevenue }}￥
                    </div>
                    <div class="cardtitle">
                        总营收
                    </div>
                </div>
            </div>
            <div class="card">
                <div>
                    <svg t="1680054501544" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="4208" width="60" height="60">
                        <path
                            d="M253.5 892.2c-104.9 0-190.2-85.3-190.2-190.2 0-76.6 45.5-145.3 116-175.2 22.8-9.7 49.1 1 58.8 23.8 9.7 22.8-1 49.1-23.8 58.8C177 625.2 153 661.5 153 702c0 55.4 45.1 100.5 100.5 100.5S354 757.4 354 702c0-24.8 20.1-44.9 44.9-44.9s44.9 20.1 44.9 44.9c-0.1 104.9-85.4 190.2-190.3 190.2zM768.1 892.2c-104.9 0-190.2-85.3-190.2-190.2 0-104.9 85.3-190.2 190.2-190.2S958.3 597.1 958.3 702c0 104.9-85.3 190.2-190.2 190.2z m0-290.7c-55.4 0-100.5 45.1-100.5 100.5s45.1 100.5 100.5 100.5S868.6 757.4 868.6 702s-45.1-100.5-100.5-100.5z"
                            fill="#36ab60" p-id="4209"></path>
                        <path
                            d="M768.1 746.9c-19.7 0-37.8-13.1-43.2-33L579.5 184.7c-3.7-13.5-0.9-28 7.6-39.1 8.5-11.1 21.7-17.7 35.7-17.7h184.8c24.8 0 44.9 20.1 44.9 44.9s-20.1 44.9-44.9 44.9h-126l129.8 472.4c6.6 23.9-7.5 48.6-31.4 55.1-4 1.2-8 1.7-11.9 1.7z"
                            fill="#36ab60" p-id="4210"></path>
                        <path
                            d="M484.4 746.9H253.5c-15.9 0-30.6-8.4-38.6-22.1-8.1-13.7-8.3-30.6-0.6-44.5l137.9-249.8-25.3-80.7h-73.3c-24.8 0-44.9-20.1-44.9-44.9s20.1-44.9 44.9-44.9h106.2c19.6 0 36.9 12.7 42.8 31.4l32.1 102.4H690c24.8 0 44.9 20.1 44.9 44.9s-20.1 44.9-44.9 44.9H462.9l64.3 204.9c4.3 13.6 1.8 28.5-6.6 40-8.5 11.6-21.9 18.4-36.2 18.4z m-154.9-89.7h93.7l-34-108.2-59.7 108.2z"
                            fill="#36ab60" p-id="4211"></path>
                    </svg>
                </div>
                <div>

                    <div class="num">
                        {{ homeData.adminCount }}

                    </div>
                    <div class="cardtitle">
                        设备总投放量
                    </div>
                </div>
            </div>
            <div class="card" v-if="store.user_type == 1">
                <div>
                    <svg t="1680054643852" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="6522" width="60" height="60">
                        <path
                            d="M749.149091 595.130182a170.682182 170.682182 0 1 1 0 341.317818 170.682182 170.682182 0 0 1 0-341.317818zM651.636364 77.218909v482.955636a219.461818 219.461818 0 0 0-86.667637 327.68H182.318545c-25.6 0-42.682182-17.035636-42.682181-42.635636V196.701091c0-21.317818 12.8-38.4 34.117818-42.682182L600.436364 47.383273c21.317818-4.282182 42.682182 8.517818 51.2 29.835636z m55.249454 579.584a17.082182 17.082182 0 0 0-28.997818 12.101818c0 4.514909 1.722182 8.843636 4.840727 11.962182l34.210909 34.071273h-36.026181a17.035636 17.035636 0 0 0 0 34.071273h51.2v34.071272h-51.2a17.035636 17.035636 0 0 0 0 34.071273h51.2v45.428364a16.989091 16.989091 0 0 0 34.024727 0v-45.381819h51.2a17.408 17.408 0 0 0 17.128727-17.221818 16.896 16.896 0 0 0-5.026909-11.915636 16.849455 16.849455 0 0 0-12.008727-4.980364h-51.2v-34.071272h51.2a17.035636 17.035636 0 0 0 0-33.978182h-36.026182l34.164364-34.164364a16.756364 16.756364 0 0 0 4.840727-11.962182 17.082182 17.082182 0 0 0-28.904727-12.101818l-42.356364 42.216727z m-12.567273-195.584l187.717819 72.564364c17.082182 8.517818 25.6 21.317818 25.6 38.4l0.046545 42.402909a218.903273 218.903273 0 0 0-213.410909-68.887273V461.218909z m-213.364363 42.682182H310.318545c-25.6 0-42.682182 17.082182-42.682181 42.682182 0 21.317818 17.082182 38.4 38.4 42.635636h174.917818c25.6 0 42.682182-17.035636 42.682182-42.635636s-17.082182-42.682182-42.682182-42.682182z m0-213.317818H310.318545c-25.6 0-42.682182 17.035636-42.682181 42.635636 0 21.364364 17.082182 38.4 38.4 42.682182h174.917818c25.6 0 42.682182-17.082182 42.682182-42.682182s-17.082182-42.635636-42.682182-42.635636z"
                            fill="#7159EB" p-id="6523"></path>
                    </svg>
                </div>
                <div>
                    <div class="num">
                        {{ (homeData.totalLeaseCount / homeData.adminCount * 100).toFixed(2) }}%

                    </div>
                    <div class="cardtitle">
                        出租率
                    </div>

                </div>
            </div>
            <div class="card" v-if="store.user_type == 1">
                <div>
                    <svg t="1680054577381" class="icon" viewBox="0 0 1033 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="5467" width="60" height="60">
                        <path
                            d="M853.333333 975.238095H170.666667c-53.638095 0-97.52381-43.885714-97.52381-97.523809V146.285714c0-53.638095 43.885714-97.52381 97.52381-97.523809h682.666666c53.638095 0 97.52381 43.885714 97.52381 97.523809v731.428572c0 53.638095-43.885714 97.52381-97.52381 97.523809z"
                            fill="#2C9EF4" p-id="5468"></path>
                        <path
                            d="M877.714286 48.761905H146.285714C65.828571 48.761905 0 114.590476 0 195.047619v165.790476C0 429.104762 58.514286 487.619048 129.219048 487.619048s129.219048-58.514286 129.219047-129.219048c0 70.704762 58.514286 129.219048 129.219048 129.219048s129.219048-58.514286 129.219047-129.219048c0 70.704762 58.514286 129.219048 129.219048 129.219048s129.219048-58.514286 129.219048-129.219048c0 70.704762 58.514286 129.219048 129.219047 129.219048s129.219048-58.514286 129.219048-129.219048V195.047619C1024 114.590476 958.171429 48.761905 877.714286 48.761905z"
                            fill="#95CEF9" p-id="5469"></path>
                        <path
                            d="M633.904762 975.238095H390.095238v-170.666666c0-68.266667 53.638095-121.904762 121.904762-121.904762s121.904762 53.638095 121.904762 121.904762v170.666666z"
                            fill="#FFFFFF" p-id="5470"></path>
                    </svg>
                </div>
                <div>
                    <div class="num">
                        {{ homeData.storeCount }}

                    </div>
                    <div class="cardtitle">
                        总门店
                    </div>

                </div>
            </div>


            <!-- <div class="card" v-if="store.user_type==1">
                <div>
                    <svg t="1680054404255" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2819" width="60" height="60"><path d="M821.333333 256a32 32 0 0 1 64 0v565.333333c0 64.8-52.533333 117.333333-117.333333 117.333334H256c-64.8 0-117.333333-52.533333-117.333333-117.333334V202.666667c0-64.8 52.533333-117.333333 117.333333-117.333334h597.333333a32 32 0 0 1 0 64H256a53.333333 53.333333 0 0 0-53.333333 53.333334v618.666666a53.333333 53.333333 0 0 0 53.333333 53.333334h512a53.333333 53.333333 0 0 0 53.333333-53.333334V256zM341.333333 437.333333a32 32 0 0 1 0-64h341.333334a32 32 0 0 1 0 64H341.333333z m0 170.666667a32 32 0 0 1 0-64h213.333334a32 32 0 0 1 0 64H341.333333z" fill="#1296db" p-id="2820"></path></svg>
                </div>
                <div>
                    
                    <div class="num">
                        {{ homeData.orderCount }}
                        
                    </div>
                    <div class="cardtitle">
                        总订单
                    </div>
                </div>
            </div> -->
            <!-- <div class="card">
                <div>
                    <el-icon size="80px" color="#fc5677">
                        <Histogram />
                    </el-icon>
                </div>
                <div>
                   
                    <div class="num">
                        {{ homeData.totalRevenue }}￥
                    </div>
                    <div class="cardtitle">
                        今日营收
                    </div>
                </div>
            </div> -->
            <div class="card">
                <div>
                    <svg t="1680054501544" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="4208" width="60" height="60">
                        <path
                            d="M253.5 892.2c-104.9 0-190.2-85.3-190.2-190.2 0-76.6 45.5-145.3 116-175.2 22.8-9.7 49.1 1 58.8 23.8 9.7 22.8-1 49.1-23.8 58.8C177 625.2 153 661.5 153 702c0 55.4 45.1 100.5 100.5 100.5S354 757.4 354 702c0-24.8 20.1-44.9 44.9-44.9s44.9 20.1 44.9 44.9c-0.1 104.9-85.4 190.2-190.3 190.2zM768.1 892.2c-104.9 0-190.2-85.3-190.2-190.2 0-104.9 85.3-190.2 190.2-190.2S958.3 597.1 958.3 702c0 104.9-85.3 190.2-190.2 190.2z m0-290.7c-55.4 0-100.5 45.1-100.5 100.5s45.1 100.5 100.5 100.5S868.6 757.4 868.6 702s-45.1-100.5-100.5-100.5z"
                            fill="#36ab60" p-id="4209"></path>
                        <path
                            d="M768.1 746.9c-19.7 0-37.8-13.1-43.2-33L579.5 184.7c-3.7-13.5-0.9-28 7.6-39.1 8.5-11.1 21.7-17.7 35.7-17.7h184.8c24.8 0 44.9 20.1 44.9 44.9s-20.1 44.9-44.9 44.9h-126l129.8 472.4c6.6 23.9-7.5 48.6-31.4 55.1-4 1.2-8 1.7-11.9 1.7z"
                            fill="#36ab60" p-id="4210"></path>
                        <path
                            d="M484.4 746.9H253.5c-15.9 0-30.6-8.4-38.6-22.1-8.1-13.7-8.3-30.6-0.6-44.5l137.9-249.8-25.3-80.7h-73.3c-24.8 0-44.9-20.1-44.9-44.9s20.1-44.9 44.9-44.9h106.2c19.6 0 36.9 12.7 42.8 31.4l32.1 102.4H690c24.8 0 44.9 20.1 44.9 44.9s-20.1 44.9-44.9 44.9H462.9l64.3 204.9c4.3 13.6 1.8 28.5-6.6 40-8.5 11.6-21.9 18.4-36.2 18.4z m-154.9-89.7h93.7l-34-108.2-59.7 108.2z"
                            fill="#36ab60" p-id="4211"></path>
                    </svg>
                </div>
                <div>

                    <div class="num">
                        {{ homeData.todayOrderCount }}

                    </div>
                    <div class="cardtitle">
                        本日新增租赁
                    </div>
                </div>
            </div>



        </div>
        <div class="chartTitle">
            门店排行和租赁物品统计
        </div>
        <div class="flex">

            <div id="chart" v-show="store.user_type == 1">


            </div>


        </div>
        <div id="chart4">

        </div>

        <div class="chartTitle">
            订单统计
        </div>
        <div id="chart3">

        </div>
    </div>

   
</template>

<script setup>
import * as echarts from 'echarts';
import { onMounted, ref, watch } from "vue";
import { useHome } from "@/use/home"
import { useMainStore } from "/store/mainStore.js"
const store = useMainStore();
const { homeData } = useHome();
const myChart = ref({})
const myChart3 = ref({})
const myChart4 = ref({})
/**
 * 销毁 Echart实例
 */
const dispose = () => {
    echarts.init(document.getElementById("chart")).dispose();

    echarts.init(document.getElementById("chart4")).dispose();
    echarts.init(document.getElementById("chart3")).dispose();
}
/**
 * 初始化 Echart实例
 */
const init = () => {
    myChart.value = echarts.init(document.getElementById("chart"))
    myChart4.value = echarts.init(document.getElementById("chart4"))
    myChart3.value = echarts.init(document.getElementById("chart3"))
}
const setOption1 = (data = []) => {
    var titles = data.map(i => i.name)
    var ds = data.map(i => 0)
    var option = {
        xAxis: {
            max: 'dataMax'
        },
        yAxis: {
            type: 'category',
            data: titles,
            inverse: true,
            animationDuration: 300,
            animationDurationUpdate: 300,
            max: ds.length - 1 // only the largest 3 bars will be displayed
        },
        series: [
            {
                realtimeSort: true,
                name: '门店收入排行',
                type: 'bar',
                data: ds,
                label: {
                    show: true,
                    position: 'right',
                    valueAnimation: true
                }
            }
        ],
        legend: {
            show: true
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
    }
    myChart.value.setOption(option);

    setTimeout(() => {
        myChart.value.setOption({
            series: [
                {
                    type: 'bar',
                    data
                }
            ]
        })
    }, 200);
    // myChart.value.setOption({
    //     title: {
    //         text: '门店收入排行',
    //         subtext: 'top10',
    //         left: 'left'
    //     },
    //     legend: {
    //         top: 'bottom'
    //     },
    //     toolbox: {
    //         show: true,
    //         feature: {
    //             mark: { show: true },
    //             saveAsImage: { show: true }
    //         }
    //     },
    //     series: [
    //         {
    //             name: 'Nightingale Chart',
    //             type: 'pie',
    //             radius: [50, 250],
    //             center: ['50%', '50%'],
    //             roseType: 'area',
    //             itemStyle: {
    //                 borderRadius: 8
    //             },
    //             data
    //         }
    //     ]
    // })
}

const setOption3 = (data) => {

    myChart3.value.setOption({
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: data.date
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {

                data: data.value,
                type: 'line',
                areaStyle: {},
                label: {
                    show: true,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '40',
                        fontWeight: 'bold'
                    }
                },
            }
        ]
    })
}
const setOption4 = () => {

    myChart4.value.setOption({
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                name: '租赁物品',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '40',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: true
                },
                data: [
                    { value: homeData.leaseInfo.idleCount, name: '闲置' },
                    { value: homeData.leaseInfo.leaseCount, name: '租赁中' },
                    { value: homeData.leaseInfo.noRetrunCount, name: '超期未还' },
                ]
            }
        ]
    })
}

/**
 * 设置 Echart 数据
 */
const setOptions = () => {
    setOption1()

    setOption3()
    setOption4()
}
onMounted(() => {
    dispose();
    init();
    //setOptions();
})
watch(() => homeData.storeOrderTop10, (data) => {
    setOption1(data)
})
watch(() => homeData.leaseInfo, (data) => {
    setOption4(data)
})
watch(() => homeData.day7, (data) => {
    setOption3(data)
})

window.addEventListener('resize', () => {
    // dispose();
    // init();
    // setOption1(homeData.storeOrderTop10)
    // setOption4(homeData.leaseInfo)
    // setOption4(homeData.day7)
});

</script>


<style scoped lang="scss">


.homepage {
    background: #f7f7f7;
    height: 100vh;

    .cardlist {
        display: flex;

        width: 100%;


        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .card {
        margin: 1vw;
        background-color: #fff;
        width: 46vw;
        height: 100px;
        display: flex;
        border-radius: 5px;

        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        transition: all 300ms ease-in-out;

        &:hover {
            width: 47vw;
            height: 110px;
            box-shadow: 15px 15px 15px #aaa;
        }

        div {
            text-align: center;
        }

        .cardtitle {
            margin-bottom: 10px;
            font-size: 12px;
            color: var(--el-color-primary);
        }

        .num {

            margin: 10px;
            font-size: 30px;
        }
    }
}

.chartTitle {
    background-color: var(--el-color-primary);
    color: #fff;
    margin: 10px 20px;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
}

#chart,
#chart3,
#chart4,
#chart5,
#chart2 {
    flex: 1;
    height: 500px;
    border-radius: 5px;
    margin: 10px 20px;
    background-color: #fff;
    box-shadow: 5px 5px 5px #eee;
    padding: 20px;
}

.flex {
    display: flex;
}</style>