<template>

<div>
    <FormComponent @submit="submit" title="新增物品型号" :data="data" canEdit="true"></FormComponent>
</div>
</template>

<script setup>
import FormComponent from '@/components/FormComponent.vue';
import {useAddItemModel} from "@/use/itemModel"
const {data,submit}=useAddItemModel();


</script>