<template>
     <div class="title">资金总览</div>
        <div class="flex zonlan">
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    总
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{total_balance}}</div>
                    <div>
                        总营收
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    可
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{dai_balance}}</div>
                    <div>
                        可提现余额
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    待
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{yi_balance}}</div>
                    <div>
                        待解冻金额
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    已
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{yi_balance}}</div>
                    <div>
                        已提现金额
                    </div>
                </div>
            </div>
        </div>
    <FormComponentVue title="资金提现" :canEdit="true" :data="data" @submit="submit">
    
   
    </FormComponentVue>

    
</template>

<script setup>
import FormComponentVue from '@/components/FormComponent.vue';

import {useCashApply} from "@/use/finance"
const {data,submit}=useCashApply();

</script>

<style scoped lang="scss">
.zonlan {
    flex: 1;
    width: 100%;
    margin-top: 20px;

    .item {
        background-color: #fff;
        height: 100px;
        border-radius: 10px;
        margin: 0 50px;
        border:1px solid rgb(161, 215, 244);
        align-items: center;

        .zs {
            background-color: var(--el-color-primary);
            width: 10px;
            border-radius: 10px;
            margin-right: 50px;
            height: 100%;
        }
        .b{
            color:#999;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            border:5px solid var(--el-color-primary);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .c{
            text-align: center;
            color: #999;
            font-size: 13px;
            .price{
                margin-bottom: 10px;
                color: var(--el-color-primary);
                font-size: 25px;
            }
        }
    }

}
</style>